<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">
    <!-- 탱크 테이블 리스트 ------------------------------------------------------>

    <vue-excel-editor
      v-model="rows"
      ref="excelGrid"
      width="100%"
      class="mb-1"
      :page="numOfRecord"
      :readonly-style="{backgroundColor:'#4F5040'}"
      :localized-label="excelEditorLabel"
      @select="selectRecord"
      filter-row>
      <vue-excel-column field="_id"         type="string"   key-field invisible/>
<!--      <vue-excel-column field="tid"         type="number"   width="80px"  label="탱크ID" readonly sticky/>-->
      <vue-excel-column field="order"       type="number"   width="50px"  label="No." readonly sticky/>
      <vue-excel-column field="mm"          type="number"   width="80px"  label="높이(mm)" :to-text="v=>{return v?v.toFixed(1):'0'}" sticky readonly/>
      <vue-excel-column field="liter"       type="number"   width="80px"  label="볼륨(L)"  :to-text="v=>{return v?v.toFixed(1):'0'}" readonly/>
      <vue-excel-column field="inch"        type="number"   width="80px"  label="높이(In)" :to-text="v=>{return v?v.toFixed(1):'0'}" readonly/>
      <vue-excel-column field="gallon"      type="number"   width="80px"  label="볼륨(GL)" :to-text="v=>{return v?v.toFixed(2):'0'}" readonly/>
      <vue-excel-column field="status"      type="string"   width="80px"  label="상태" :to-text="v=>{return v?statusMap[v]:'n/a'}" readonly/>
      <vue-excel-column field="remark"      type="string"   width="100px" label="비고" readonly/>

      <vue-excel-column field="updatedAt"   type="date"     width="110px" label='수정일' :to-text="toLocalTime" readonly/>
      <vue-excel-column field="updId"       type="string"   width="80px" label='수정자' readonly/>
      <vue-excel-column field="createdAt"   type="date"     width="110px" label='등록일' :to-text="toLocalTime" readonly/>
      <vue-excel-column field="regId"       type="string"   width="80px" label='등록자' readonly/>
    </vue-excel-editor>

<!--    <BCard no-body border-variant="dark" class="p-1 m-1">-->
    <BRow v-show="rows.length>0">
      <BCol sm="6">
        <BButton variant="primary" size="sm" :disabled="isApplying"
                 @click="applyTable">
          <BIconBootstrapReboot/> 설정 반영
        </BButton>
      </BCol>
      <BCol>
        <BButton block variant="danger" size="sm"
                 @click="deleteRecord"
                 :disabled="selectedRows.length===0">
          <BIconTrashFill/> 선택 삭제
        </BButton>
      </BCol>
      <BCol class="text-right">
        <BButton block variant="info" size="sm"
                 :disabled="isUploadMode"
                 @click="downloadTable()">
          <BIconDownload/> 다운로드
        </BButton>
      </BCol>
    </BRow>

    <BRow v-show="rows.length>0" class="mt-1">
      <BCol>
        <BButton variant="outline-danger" size="sm"
                 @click="transToPresent"
                 v-show="status!==1&&rows.length!==0">
          현재값 으로 변환
          <div class="sk-swing float-right" style="height:25px;width:25px" v-if="isTransRecord" >
            <div class="sk-swing-dot"></div>
            <div class="sk-swing-dot"></div>
          </div>
        </BButton>
      </BCol>

      <BCol>
        <BButton block variant="outline-warning" size="sm"
                 @click="transUnit('mm')"
                 :disabled="isUpdatingRecord">
          ㎜/리터 변환
          <div class="sk-swing float-right" style="height:25px;width:25px" v-if="isUpdatingRecord" >
            <div class="sk-swing-dot"></div>
            <div class="sk-swing-dot"></div>
          </div>
        </BButton>
      </BCol>

      <BCol>
        <BButton block variant="outline-warning" size="sm"
                 @click="transUnit('inch')"
                 :disabled="isUpdatingRecord">
          인치/갤런 변환
          <div class="sk-swing float-right" style="height:25px;width:25px" v-if="isUpdatingRecord" >
            <div class="sk-swing-dot"></div>
            <div class="sk-swing-dot"></div>
          </div>
        </BButton>
      </BCol>
    </BRow>
<!--    </BCard>-->


    <CCard accent-color="info" class="mt-3" v-if="tid">
      <CCardHeader>
        <BBadge variant="danger" class="mr-1"> <b>[{{tank.tankCode}}]</b> </BBadge>
        <B class="text-warning bg-dark">{{tank.name}}</B> 탱크 테이블 업로드

        <div class="card-header-actions">
          <small class="text-muted">mm | liter 또는 inch | gallon 파일 업로드</small>
        </div>
      </CCardHeader>

      <CCardBody >
        <BRow>
          <BCol class="mb-3">
            <BInputGroup>
              <BFormFile
                size="sm"
                class="mr-2"
                accept=".xls, .xlsx, .cell"
                variant="warning"
                v-model="selectedFile"
                placeholder="파일선택 후 파일확인"
                drop-placeholder="여기에 파일 끌어 놓기"
                :disabled="tid===null"
              />
              <BButton size="sm" variant="primary" class="mr-1"
                       :disabled="selectedFile===null"
                       v-b-tooltip.hover.top="'내용 확인 후 테이블을 업로드 하세요'"
                       @click="parseFile"
              >
                파일확인
              </BButton>
              <BButton size="sm" variant="warning" v-show="isUploadMode"
                       :disabled="selectedFile===null"
                       @click="uploadTable"
              >
                <div class="sk-swing float-right" style="height:20px;width:20px" v-if="isUpdatingRecord" >
                  <div class="sk-swing-dot"></div>
                  <div class="sk-swing-dot"></div>
                </div>
                <BIconUpload/> 테이블 업로드
              </BButton>
            </BInputGroup>
          </BCol>
        </BRow>
      </CCardBody>

    </CCard>

    <!--
    <CDataTable
      v-if="false"
      size="sm"
      class="small"
      :items="oils"
      :fields="oilFields"
      items-per-page-select
      :items-per-page="10"
      hover
      sorter
      pagination
      :columnFilter="!simple"
      :tableFilter="!simple"
      cleaner
    >
      <template #color="{item}">
        <td class="align-middle">
          <BButton block
                   :style="{backgroundColor: item.color, color:'black'}" size="sm">{{ item.color }}
          </BButton>
        </td>
      </template>
    </CDataTable>
    -->



  </div>
</template>



<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall, cloneVar, sleep
} from '@/common/utils';
import con, {ExcelEditorLabel} from "@/common/constants";
import * as XLSX from 'xlsx';
import moment from 'moment';

const _oil = {
  code           : '',
  name           : '신규유종',
  oilTempGroup   : '',
  expansionRate  : '',
  density        : '',
  prdCode        : '',
  color          : '',
  stockpile      : '',
  dayConsumption : '',
};


//----------------------------------------------------------------------------------------------------
export default {
  name: "TankTableList",
  components: {
  },
  props: {
    tid: { type: String, default: null },
    status: { type: Number, default: 1 },
    simple: { type: Boolean, default: false },
  },
  data () {
    return {
      con,
      tank: null,
      tnm: null,
      tankMap: this.$store.state.tanks.map,
      selectedFile: null,
      isUpdatingRecord: false,
      isTransRecord: false,
      isUploadingTable: false,
      numOfRecord: 20,
      pickerShow: false,
      oilCodeMap: this.$store.state.codeMaps['OIL_CODE'],
      tankTypeMap: this.$store.state.codeMaps['TANK_TYPE'],
      isCreateMode: false,
      isUploadMode: false,
      rows: [],
      selectedRows: [],
      oilTempOpts:[
        {value:null, label:'체적그룹 선택'},
        {value:'1',  label:'그룹 1'},
        {value:'2',  label:'그룹 2'},
        {value:'3',  label:'그룹 3'},
        {value:'4',  label:'그룹 4'},
        {value:'5',  label:'그룹 5'},
        {value:'6',  label:'그룹 6'},
        {value:'7',  label:'그룹 7'},
        {value:'0',  label:'그룹 0'},
      ],
      formShow: false,
      isSubmitting: false,
      row: null,
      statusMap: { '0':'신규', '1':'현재', '2':'이전' },
      isApplying: false,
    }

  },
  async created(){
    try{
      console.log("--- TankTableList created--------------------- tid ------", this.tid);
      console.log("--- TankTableList created--------------------- status ---", this.status);
      this.tank = this.tankMap[this.tid];

      await this.getList();
    }catch(err){
      console.log(err);
    }
  },

  computed: {
    excelEditorLabel() {
      return ExcelEditorLabel
    }
  },

  mounted() {
    console.log("--- TankTableList mounted---------------------");
    // below is not work!
  },

  methods: {

    async applyTable(){
      try{
        if(!this.tid){
          return await this.alertDanger('탱크를 선택 하세요.');
        }
        this.isApplying = true;
        await this.toastAlarm('시스템 반영중','탱크 테이블을 반영중 입니다.', 'primary');
        const r = await apiCall('post', `/api/tank/apply/${this.tid}`);
        await sleep(3000);
        await this.toastResult(r, '탱크 테이블 시스템 반영');
      }catch(err){
        console.log(err);
        await this.toastError(err);
      }finally {
        this.isApplying = false;
      }
    },

    downloadTable(){
      if(this.tid  && this.rows.length && !this.isUploadMode ){
        let filename = `tank-table-${this.tid}-${moment().format('YYMMDD')}.xlsx`;
        this.$refs["excelGrid"].exportTable('xlsx', false, filename);
      }else{
        this.alertWarn('탱크 테이블을 다운로드 할 수 없습니다.');
      }

    },

    async uploadTable(){
      try{
        if(!this.tid){
          return await this.alertDanger('탱크번호가 없습니다.');
        }

        this.isUploadingTable = true;
        for(let r of this.rows){
          r.status = 1;
        }
        const r = await apiCall('post', `/api/tank-table/${this.tid}`, this.rows );
        if(r.code===200){
          await this.getList();
          this.isUploadMode = false;
        }
        await this.toastResult(r, '탱크테이블 업로드');

      }catch(err){
        console.log(err);
        await this.toastError(err);
      }finally{
        this.isUploadingTable = false;
      }
    },
    async parseFile(){
      console.log("############## fileSelected", this.selectedFile);
      try{
        if( !this.selectedFile ) return;


        const reader = new FileReader();
        // let tmpResult = {};

        reader.onload = async (e) => {
          let data = e.target.result;
          data = new Uint8Array(data);
          let excelFile = XLSX.read(data, {type:'array'});


          let sheetName = null;
          if( excelFile.SheetNames.length ) {
            sheetName = excelFile.SheetNames[0];
            const sheet = XLSX.utils.sheet_to_json(
              excelFile.Sheets[sheetName],
              { header: 1 }
            );


            // console.log( sheet );
            this.tanktableRowCount = '입력 수:'+(sheet.length-1) + ' 개 (1행 제외)'  ;
            this.fileTableItems = sheet;
            let isError = false;
            // let header = sheet[0];
            const headers = sheet[0].map( i=> { return i.toLowerCase()} );

            console.log('---------------header----------------\n',headers);

            let headerPass = false;
            let dataType = 1;

            if(headers.length < 1) {
              await this.toastInfo('높이 또는 부피 컬럼 정보가 부족합니다.');
              return false;
            }

            const hcol = headers[0].toLowerCase();
            const vcol = headers[1].toLowerCase();

            if( hcol.indexOf('mm') >-1 &&
              ( vcol.indexOf('liter') > -1 || vcol.indexOf('li') > -1) ){
                await this.toastInfo('밀리미터 & 리터 컬럼 정보 정상');
                headerPass = true;
            }

            if( !headerPass && ( hcol.indexOf('inch') >-1 || hcol.indexOf('in') > -1 ) &&
              (vcol.indexOf('gallon') > -1 || vcol.indexOf('gal') > -1 || vcol.indexOf('gl') > -1 || vcol.indexOf('g/l') > -1) ){
              await this.toastInfo('인치 & 갤런 컬럼 정보 정상');
              headerPass = true;
              dataType = 2;
            }

            if( !headerPass ){
              const msg = '첫번째 레코드는 컬럼명이며 "mm 또는 inch, liter(li) 또는 gallon(gal)" 순으로 구성해야 합니다.'
              this.toastWarn(msg);

              return false;
            }

            let errCount=0;
            this.rows = [];

            if( dataType===1 ){
              for( let i=1; i < sheet.length; i++ ) {

                try{
                  // console.log( sheet[i][0], sheet[i][1] );

                  let mm = sheet[i][0];
                  let liter = sheet[i][1];
                  let order = i;
                  let status = '0';


                  if( isNaN(mm) ) {
                    mm = 0;
                    this.toastWarn(`${i} 번째 레코드 밀리미터 컬럼 오류`)
                  }
                  if( isNaN(liter) ) {
                    liter = 0;
                    this.toastWarn(`${i} 번째 레코드 리터 컬럼 오류`)
                  }

                  mm = Number(mm);
                  liter = Number(liter);


                  this.rows.push({tid: this.tid, order, mm, liter, status});


                }catch(err){
                  console.log(err);
                }

              }

            }else{
              for( let i=1; i < sheet.length; i++ ) {

                try{
                  // console.log( sheet[i][0], sheet[i][1] );

                  let inch = sheet[i][0];
                  let gallon = sheet[i][1];
                  let order = i;
                  let status = '0';


                  if( isNaN(inch) ) {
                    inch = 0;
                    this.toastWarn(`${i} 번째 레코드 인치컬럼 오류`)
                  }
                  if( isNaN(gallon) ) {
                    gallon = 0;
                    this.toastWarn(`${i} 번째 레코드 갤런컬럼 값오류`)
                  }

                  inch = Number(inch);
                  gallon = Number(gallon);

                  this.rows.push({tid: this.tid, order, inch, gallon, status});


                }catch(err){
                  console.log(err);
                }


              }
            }


            console.log( isError );
            if( errCount > 0 ){
              await this.alertModal('파일에 에러가 있습니다.');
            }else{
              await this.alertModal('에러가 없습니다. 업로드 버튼을 클릭하여 업로드 하세요', '파일 확인 완료');
            }

            await this.toastInfo( `읽은 레코드 수는 ${this.rows.length} 개 입니다`);

            this.isUploadMode = this.rows.length > 0;


          }
          /*
          excelFile.SheetNames.forEach((sheetName)=>{
            const roa = XLSX.utils.sheet_to_json(
              excelFile.Sheets[sheetName],
              { header: 1 }
            );
            if( roa.length ) tmpResult[sheetName] = roa;

            console.log(tmpResult);
          });
          */
        };

        reader.readAsArrayBuffer(this.selectedFile);
      }catch(err){
        console.log(err);
      }

    },
    async transToPresent(){

      try{
        if(this.selectedRows.length===0){
          this.toastWarn('선택된 레코드가 없습니다.')
          return;
        }
        let confirmMsg = `이전 테이블 ${this.status}번의 레크드 ${this.selectedRows.length}개를 현재 테이블로 설정 합니다. 진행 하시겠습니까?`;
        if( !(await this.confirmModal(confirmMsg, '현재 값으로 변환')) ){
          return;
        }

        this.isTransRecord = true;
        const r = await apiCall(
          'post',
          `/api/tank-table/trans-to-present/${this.tid}/${this.status}`,
          this.selectedRows
        );
        await this.toastResult(r);

        if(r.code===200){
          await this.getList();
          await this.alertSuccess( r.result.updateCount+' 건이 현재값으로 변경 되었습니다. 시스템 반영이 필요합니다.' );
        }

      }catch(err){
        console.log(err);
        this.toastError(err);
      }finally{
        this.isTransRecord = false;
      }

    },

    async transUnit(unit){
      let confirmMsg
      try{

        confirmMsg = (unit==='mm')?
          '인치/겔런을 기준으로 밀리미터/리터 단위 테이블을 생성합니다. 진행 하시겠습니까?'
          : '밀리미터/리터를 기준으로 인치/갤런 단위 테이블을 생성합니다. 진행 하시겠습니까?';

        if( !(await this.confirmModal(confirmMsg, '단위 변환 생성')) ){
          return;
        }


        this.isUpdatingRecord = true;
        const r = await apiCall('post', `/api/tank-table/trans-to/${this.tid}/${this.status}/${unit}`);
        if(r.code===200){
          this.rows = r.result;
          await this.getList();
        }
      }catch(err){
        console.log(err);
        this.toastError(err);
      }finally{
        this.isUpdatingRecord = false;
      }
    },
    async createRow(){
      try{
        this.isCreateMode = true;
        await this.$refs.table.clearSelected();
        this.row = cloneVar(_oil);
      }catch(err){
        console.log(err);
        this.isCreateMode = false;
      }
    },

    async getList(){
      try{
        if(!this.tid) return;
        console.log( "getList() --- query-string ----> ");
        this.rows = [];
        let query = `status=${this.status}`;
        const r = await apiCall('get', `/api/tank-table/${this.tid}?${query}`);
        if(r.code===200){
          this.rows = r.result;
          await this.toastInfo(`탱크 테이블 정보 ${this.rows.length}건 조회됨`, 'info');

        }
        this.selectedTank = null;

      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }

    },

    async selectRecord(idx, evt){

      if(!evt){
        // this.tankFormShow = false;
        this.selectedRows = [];
        return;
      }
      console.log("------------ selectRecord ------------", idx.length);
      this.selectedRows = this.$refs['excelGrid'].getSelectedRecords();
    },

    async deleteRecord(){
      // let r = null, msg = null, notice = null;
      try{

        let params = this.selectedRows;
        this.progCount = 0;
        this.totalCount = params.length;
        if(!params.length) return this.toastInfo('[선텍된 레코드 없음] 좌측의 번호를 클릭하여 레코드 선택');

        const confirmMsg = `${params.length} 개의 데이터를 삭제 합니다. 삭제된 데이터는 복구할 수 없으며 측정 오류가 발생 할 수 있습니다. 진행 하시겠습니까?`;

        if( !(await this.confirmModal(confirmMsg, '레코드 삭제')) ){
          return;
        }

        let failCnt = 0;
        for( let record of params){
          console.log( "deleteRecord for --------->", record );
          const tIndex = this.$refs['excelGrid'].rowIndex[record.$id];
          const r = await apiCall('DEL', `/api/tank-table/${record._id}`);
          console.log( r );
          if( r.code===200 ){
            this.progCount++;
            this.$refs["excelGrid"].deleteRecord(tIndex);
          }
        }

        await this.alertModal(`삭제: ${this.progCount}건, 실패: ${failCnt}`, '레코드 삭제결과', 'warning');
        this.$refs['excelGrid'].clearAllSelected();
        this.selectedRows = [];
        await this.getList();

      }catch(err){

        console.log( err );
      }
    },




  } // end of methods
}
</script>
