<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">
    <!-- 탱크 테이블 - 온도별 환산체적 ------------------------------------------------------>

    <BRow>

    </BRow>
    <BRow>


      <BCol class="text-left">
        <BButton size="sm" variant="primary"
                 @click.prevent="downloadTable"
                 :disabled="rows.length===0||isDownloading">
          <BIconFileSpreadsheetFill/>
          다운로드
        </BButton>
      </BCol>
      <BCol>
        <!--        <b-icon icon="arrow-clockwise" animation="spin-pulse" font-scale="4" v-if="isProcessing"></b-icon>-->
        <div class="sk-swing float-center" v-if="isProcessing||isDownloading" >
          <div class="sk-swing-dot" style="width:20px;height:20px"></div>
          <div class="sk-swing-dot" style="width:20px;height:20px"></div>
        </div>

      </BCol>
      <BCol>
        <BPagination
          align="right"
          size="sm"
          v-model="page"
          :total-rows="totalRows"
          :per-page="perPage"
          @input="getList"
        ></BPagination>
      </BCol>

    </BRow>

<!--    <div style="width:100%;overflow:auto; max-height:600px;" v-if="rows.length!==0">-->
    <div class="view">
      <div class="wrapper">
      <table class="table small table-bordered text-nowrap text-right">
        <thead>
          <tr class="text-center font-weight-bold" bgcolor="#404040">
            <th class="align-text-top sticky-col first-col" rowspan="3">No.</th>
            <th class="align-text-top sticky-col second-col" rowspan="3">㎜</th>
<!--            <th rowspan="2">높이 <br> (Inch)</th>-->
            <th class="text-left" :colspan="colCount"> 온도</th>
          </tr>
          <tr class="text-center font-weight-bold" bgcolor="#334033">
            <th v-for="i in colCount" :key="i"> {{i-1}} °F</th>
          </tr>
          <tr class="text-center small font-weight-bold" bgcolor="#334033">
            <th v-for="i in colCount" :key="i">{{ (((i-1) - 32) / 1.8).toFixed(1)}} °C</th>
          </tr>
        </thead>
        <tbody class="small">
          <tr v-for="r in rows" :key="r.order">
            <td class="sticky-col first-col" bgcolor="#505550">{{r.order}}</td>
            <td class="sticky-col second-col" bgcolor="#505550">{{r.mm.toFixed(1)}}</td>
<!--            <td bgcolor="#505550">{{r.inch.toFixed(1)}}</td>-->
            <td v-for="(v,i) in r.temps" :key="i">{{v.toFixed(1)}}</td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
<!--    </div>-->

<!--

      <BTableSimple small sticky-header dark class="small min-vh-100">
        <BThead>
          <BTr class="text-nowrap">
            <BTh sticky-column class="align-middle text-center font-weight-bold">번호</BTh>
            <BTh sticky-column class="align-middle text-center font-weight-bold">높이 (mm)</BTh>
            <BTh sticky-column class="align-middle text-center font-weight-bold">높이 (Inch)</BTh>
            <BTh v-for="i in colCount" :key="i" class="text-nowrap">
              {{i-1}} °F<br/>{{ (((i-1) - 32) / 1.8).toFixed(1)}} °C
            </BTh>
          </BTr>
        </BThead>

        <BTbody class="text-right">
          <BTr v-for="r in rows" :key="r.order">
            <BTd sticky-column>{{r.order}}</BTd>
            <BTd sticky-column>{{r.mm.toFixed(1)}}</BTd>
            <BTd sticky-column>{{r.inch.toFixed(1)}}</BTd>
            <BTd v-for="(v,i) in r.temps" :key="i">{{v.toFixed(1)}}</BTd>
          </BTr>
        </BTbody>
      </BTableSimple>
-->





  </div>
</template>

<style src="spinkit/spinkit.min.css"></style> <!--animated processing icon-->


<style>
/*
table,tr,th,td{
  border:1px solid;
  border-collapse:collapse;
}
*/

.table th, .table td {
  padding: 0.2rem;
}

/*-----------------------------*/
.view {
  margin: auto;
}

.wrapper {
  position: relative;
  overflow: auto;
  border: 1px solid #565656;
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
}

.first-col {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  left: 0px;
}

.second-col {
  width: 50px;
  min-width: 50px;
  max-width: 80px;
  left: 40px;
}


</style>


<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall
} from '@/common/utils';
import moment from 'moment';

/*
const _oil = {
  code           : '',
  name           : '신규유종',
  oilTempGroup   : '',
  expansionRate  : '',
  density        : '',
  prdCode        : '',
  color          : '',
  stockpile      : '',
  dayConsumption : '',
};

*/

//----------------------------------------------------------------------------------------------------
export default {
  name: "OilTempTable",
  components: {
  },
  props: {
    tid: { type: String, default: null },
    groupIndex: { type: String, default: null },
    simple: { type: Boolean, default: false },
  },
  data () {
    return {
      colCount: 0,
      totalRows: 0,
      page: 1,
      pages: 0,
      perPage: 20,

      isProcessing: false,
      isDownloading: false,
      numOfRecord: 20,
      pickerShow: false,
      oilCodeMap: this.$store.state.codeMaps['OIL_CODE'],
      tankTypeMap: this.$store.state.codeMaps['TANK_TYPE'],
      isCreateMode: false,
      rows: [],
      oilTempOpts:[
        {value:null, label:'체적그룹 선택'},
        {value:'1',  label:'그룹 1'},
        {value:'2',  label:'그룹 2'},
        {value:'3',  label:'그룹 3'},
        {value:'4',  label:'그룹 4'},
        {value:'5',  label:'그룹 5'},
        {value:'6',  label:'그룹 6'},
        {value:'7',  label:'그룹 7'},
        {value:'0',  label:'그룹 0'},
      ],
      formShow: false,
      isSubmitting: false,
      row: null,
    }

  },
  async created(){
    try{
      this.rows = [];
      console.log("--- TankTableOilTemp created---------tid,index=", this.tid, this.groupIndex);
      await this.getList();
    }catch(err){
      console.log(err);
    }
  },

  computed: {},

  mounted() {
    console.log("--- TankTableOilTemp mounted---------------------");
    // below is not work!
  },

  methods: {

    async getList(){
      try{

        this.isProcessing = true;
        this.rows = [];
        if(!this.tid || !this.groupIndex) {
          // this.toastWarn( '탱크를 선택하세요');
          return;
        }

        let query = `page=${this.page}&perPage=${this.perPage}`;
        console.log( "getList() --- query-string ----> ");
        this.rows = [];
        const r = await apiCall('get', `/api/tank-table/oil-temp/${this.tid}/${this.groupIndex}?${query}`);
        if(r.code===200){
          this.rows = r.result.table;
          this.colCount = r.result.numberOfColumn;
          this.page = r.result.page;
          this.totalRows = r.result.totalRows;
          this.pages = r.result.pages;
          console.log( 'result ---->', r.result );

          await this.toastInfo(`온도별 체적환산 정보 ${this.rows.length}건 조회됨`, 'info');
        }


      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }finally{
        this.isProcessing = false;
      }

    },

    async downloadTable(){
      try{
        console.log( this.tid, this.groupIndex);

        if( !(this.tid && this.groupIndex)) {
          this.toastWarn( '탱크를 선택하세요');
          return;
        }

        this.isDownloading = true;
        const r = await apiCall('getfile', `/api/tank-table/oil-temp-download/${this.tid}/${this.groupIndex}`);
        const fileName = `${this.tid}-${moment().format('YYMMDD')}.xlsx`;
        await this.getDownload(fileName, r);
      }catch(err){
        console.log(err);
        this.toastError(err);
      }finally{
        this.isDownloading = false;
      }

    },
    clearTable(){
      this.rows = [];
      this.colCount = 0;
      this.totalRows = 0;
      this.page = 1;
      this.pages = 0;
      this.perPage = 20;
    }


  } // end of methods
}
</script>
