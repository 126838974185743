<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }">
    <!-- 유정 및 체적환산 관리 --------------------------------------------------------------------------------------------->

      <BCard header-tag="header" footer-tag="footer">
        <div slot="header">
          <BIconFileRuledFill/> <strong> 탱크 테이블 설정 </strong>
          <div class="card-header-actions">
            <small class="text-muted">탱크 테이블을 구성하고 탱크별 온도별 환산 체적비율를 조회합니다.</small>
          </div>
        </div>

        <BRow>
          <BCol xl="6">

            <CCard border-color="info" style="min-height:500px">
              <CCardHeader>
                <BRow>
                  <BCol>
                    <BIconTable/> 탱크 테이블
                  </BCol>

                  <BCol>
                    <CSelect size="sm" class="mb-0"
                             :value.sync="selectedStatus"
                             @change="changeTid"
                             :options="statusOpts"
                    />
                  </BCol>

                  <BCol class="float-right">
                    <CSelect size="sm" class="mb-0"
                             :value.sync="tid"
                             :state="!tid"
                             @change="changeTid"
                             :options="tankOpts"
                      />

                  </BCol>

                   <!-- 새로고침 버튼 추가 -->
                  <BCol class="float-right">
                    <BButton size="sm" variant="primary" @click="refreshPage">
                      <BIconArrowRepeat/>
                    </BButton>
                  </BCol>

                </BRow>
              </CCardHeader>

              <CCardBody>

                <tank-table-list
                  :tid="tid"
                  :status="selectedStatus"
                  :key="tableKey"
                />

              </CCardBody>
            </CCard>

          </BCol>
          <BCol xl="6">
            <CCard border-color="info" style="min-height:500px">
              <CCardHeader>
                <BRow>
                  <BCol>
                    <BIconTable/> 온도별 체적 환산표
                  </BCol>
                  <BCol class="text-right" v-if="tid">
                    <BButton size="sm" variant="primary"
                             :disabled="!tid"
                             @click="getOilTempTable">
                      <BIconFileEarmarkSpreadsheetFill/>
                      [{{tid}}] {{tnm}} 체적 환산표 조회
                    </BButton>
                  </BCol>

                </BRow>
              </CCardHeader>

              <CCardBody>
                <oil-temp-table ref="oilTempTable" :tid="tid" :group-index="groupIndex" :key="oilTempKey"/>
              </CCardBody>
            </CCard>



          </BCol>
        </BRow>

      </BCard>


  </div>
</template>

<style src="spinkit/spinkit.min.css"></style> <!--animated processing icon-->

<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall,
  cloneVar,
} from '@/common/utils';
import excelEditorLabel from "@/common/constants";
import TankTableList from '../components/TankTableList'
import OilTempTable from '../components/OilTempTable'
// import qs from 'querystring';
// import moment from "moment";
// import {excelEditorLabel} from '@/common/constants'


const _oil = {
  code           : '',
  name           : '',
  oilTempGroup   : '',
  expansionRate  : '',
  density        : '',
  prdCode        : '',
  color          : '',
  stockpile      : '',
  dayConsumption : '',
};




//----------------------------------------------------------------------------------------------------
export default {
  name: "Oil",
  components: {
    OilTempTable,
    TankTableList,
  },

  computed: {},

  data () {
    return {
      tableKey: 0,
      oilTempKey: 100000,
      tankOpts: [],
      tankMap: null,
      tid: '',
      tnm: '',
      selectedStatus: 1,
      groupIndex: null,
      statusOpts: [
        {value:1, label:'현재값'},
        {value:2, label:'이전값-2'},
        {value:3, label:'이전값-3'},
        {value:4, label:'이전값-4'},
        {value:5, label:'이전값-5'}
      ],

      isSiteSaving: false,
      maps: this.$store.state.codeMaps,
      tanks: this.$store.state.tanks['map'],
      oil: cloneVar(_oil),
      accountOpts: [
        {value: '', label: '계정 선택'},
        {value: 'smartpol', label: '[smartpol] 시스템 관리자'}
      ],
      accountMap: {
        smartpol : '시스템관리자'
      },
      editorLabel: excelEditorLabel,
      selectedGroup: null,
      arCodeMap  : {},
      arCodeOpts  : [],
      downloadReason: '',
      searchWord : '',
      progCount: 0,
      totalCount: 0,
      rows: [],

      paramMap: {},
      numOfRecord: 10,
      siteFields: {

      },
      modalTitle: '',
      tankFormShow: false,

    }

  },
  async created(){
    try{
      await this.getTankOptions();
      console.log('created()---------tanks---------', this.tanks)
    }catch(err){
      console.log(err);
    }
  },


  mounted() {
    console.log("--- site mounted---------------------");
    // below is not work!
  },

  methods: {
    changeTid(){
      this.tableKey++;
      this.$refs.oilTempTable.clearTable();
      this.tnm = this.tankMap[this.tid].name;
    },

    getOilTempTable(){
      let tid = this.tid;
      let tank = this.tankMap[tid];

      console.log( 'tank ---->', tank );
      console.log( 'oil --->', this.$store.state.codeMaps.OIL[tank.oilCode] );

      this.groupIndex = this.$store.state.codeMaps.OIL[tank.oilCode].oilTempGroup

      // this.tid = tid;
      this.oilTempKey++;
    },

    clearOilTempTable(){

    },

    async refreshPage() {
      this.tid = '';
      this.tableKey = 0;
      await this.getTankOptions();
    },

    async getTankOptions(){
      try{
        const {result} = await apiCall('get', `/api/tank/init`);
        this.tankOpts = result.opts;
        this.tankMap = result.map;
        console.log( "getTankOptions---------", this.tankMap );

      }catch(err){
        console.log(err);
      }

    },


    async selectRecord(idx, evt){
      if(!evt){
        this.selectedGroup = null;
        return;
      }

      this.selectedGroup = this.$refs['excelGrid'].getSelectedRecords().pop();

    },

    setGroup(groupIndex){
      console.log('at parent --- setGroup--->', groupIndex);
      this.groupIndex = groupIndex;
      this.oilTempKey++;
    },



  }
}
</script>
<style>
.v-scroll-button {
  background-color: #888 !important;
  display: block !important;
  width: 12px;
  border-radius: 6px;
}

.v-scroll-button .runner {
  display: block !important;
}

.vue-excel-editor .table-content .systable .center-text .table-col-header {
  pointer-events: none;
}
</style>
